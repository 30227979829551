/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

.flip-card {
  transform-style: preserve-3d;
  transition: $euiAnimSpeedFast linear;
  position: relative;
  min-height: 420px;
  height: inherit;
  width: inherit;

  &.showBack {
    transform: rotateY(180deg);
  }

  .side {
    display: flex;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;

    &.front {
      transform: rotateY(0);
      position: absolute;
      top: 0;
      left: 0;
    }

    &.back {
      transform: rotateY(180deg);
    }
  }
}
