/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../styles/variables.scss';

.euiTheme-dark {
  .cuiBeveledIcon {
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.4);
    background-color: $euiColorDarkestShade;
  }
}

.cuiBeveledIcon {
  width: 32px;
  height: 32px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: relative;
  border: none;
  background-color: $euiColorLightestShade;

  &.cuiBeveledIcon_warning {
    background-color: fade_out($euiColorWarning, 0.4);
  }

  &.cuiBeveledIcon_danger {
    background-color: fade_out($euiColorDanger, 0.7);
  }

  .euiIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
