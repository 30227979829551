/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import './variables.scss';

@import './root.scss';
@import './base.scss';

@import './cards.scss';
@import './fonts.scss';
@import './grid.scss';
@import './tables.scss';
@import './lists.scss';
@import './tooltips.scss';
@import './utils.scss';
@import './utils-text.scss';

.app-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -16px;
  margin-top: -19px;
}

.cloudSidebar {
  flex: 1;
  max-width: 15rem;
  min-width: 15rem;
  padding: $paddingJumbo $paddingLargest;

  .euiTheme-dark & {
    border-right-color: $euiColorDarkestShade;
  }

  @media only screen and (max-width: $euiBreakpointM) {
    padding: 0;
    max-width: none;
    border-right: none;
    margin-bottom: -0.5625rem;
  }

  .euiSideNavItem {
    padding: 0;
  }
}

.cloudContent {
  flex: 5;
  padding: $paddingJumbo;

  @media only screen and (max-width: $euiBreakpointM) {
    padding: $paddingEvenLarger;
    margin-top: 0.56rem;
  }
}

.cloudContent.createPage {
  min-height: 100%;
  box-shadow: none;
}

.createPage, .createPage .cloudContentBody {

  @media only screen and (max-width: $euiBreakpointM) {
    padding: $paddingSmall;
  }
}

.euiPageBody {
  min-height: 100%;
}

.cloudContent .cloudContentBody {
  max-width: 1320px;
  margin: 0 auto;
  border: none;
  padding: 0;
  box-shadow: none;
}

.panel-with-border {
  border: 1px solid lighten($euiColorLightShade, 8%);

  .euiTheme-dark & {
    border-color: $euiColorDarkestShade;
  }
}
