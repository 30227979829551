/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

.deploymentTemplate-indexPatterns {
  list-style-type: disc;
  list-style-position: inside;
}

.deploymentTemplate-ilmBadge {
  margin-left: $paddingSmall;
}

.deploymentTemplate-indexManagement {
  margin-top: $paddingEvenLarger;
}

.deploymentTemplate-subTitle {
  margin-top: $paddingSmall;
}

.plugin-name {
  font-weight: $euiFontWeightMedium;
}

.deploymentTemplate-esLogo {
  margin-right: $paddingSmall;
}

.deploymentTemplate-versionBadge {
  margin-left: $paddingSmall;
}

.deploymentTemplate-attributeTitle {
  white-space: nowrap;
}
