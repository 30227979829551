/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

.account-cost-analysis {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .account-cost-analysis-content {
    position: relative;
    min-height: 100%;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 315px;
      border-left: 1px solid $euiColorLightShade;
    }
  }

  .account-cost-analysis-grid {
    padding-right: $paddingJumbo;
    margin-right: $paddingLargest;

    .cost-analysis-grid-no-wrap-text {
      word-break: normal;
      white-space: nowrap;
    }
  }
}

.euiTheme-dark {
  .account-cost-analysis-content {
    &::after {
      border-left-color: $euiColorDarkestShade;
    }
  }
}

@media only screen and (max-width: $euiBreakpointM) {
  .account-cost-analysis {
    .account-cost-analysis-content {
      &::after {
        border-left: none;
      }
    }
  }
}
