/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

.allocatorCapacity {
  display: flex;
  height: 1rem;
}

.allocatorCapacityKinds {
  display: block;
  width: 100%;
  height: 4px;
  margin-top: 3px;
  pointer-events: none;
}

.allocatorCapacityKind {
  height: 4px;
}

.allocatorCapacity-used,
.allocatorCapacity-unused {

  &:first-child {
    &,
    .allocatorCapacity-kind::after {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }
  }

  &:last-child {
    &,
    .allocatorCapacity-kind::after {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }
}

.allocatorCapacity-unused-color {
  background-color: $euiColorLightShade;
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 5px,
    rgba(255,255,255,.5) 5px,
    rgba(255,255,255,.5) 10px
  );

  .euiTheme-dark & {
    background-color: $euiColorDarkestShade;
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 5px,
      rgba(255,255,255,.2) 5px,
      rgba(255,255,255,.2) 10px
    );
  }

  .isUnhealthy & {
    background-color: rgba(255, 0, 0, 0.15);
  }
}

@media only screen and (max-width: ($tableSmall - $subPixel)) {
  .allocatorCapacity-used,
  .allocatorCapacity-unused {
    pointer-events: none;
    cursor: default;
  }
}
