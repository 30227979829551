/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../styles/variables.scss';

.cuiFileUploadButton-form {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}

.cuiFileUploadButton-dropzone {
  display: block;
  position: relative;
  text-align: center;
  margin: 0;
}

.cuiFileUploadButton-dropzone--active {
  background: $euiColorSuccess;
}

.cuiFileUploadButton-dropzone--hover {
  background: $euiColorPrimary;
}

.cuiFileUploadButton-dropzone--active,
.cuiFileUploadButton-dropzone--hover {
  border-radius: $borderRadius;

  &::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 9px;
    text-align: center;
    color: $euiColorEmptyShade;
    content: 'Drop files here …';
  }

  .euiButton {
    visibility: hidden;
  }
}

.cuiFileUploadButton-input {
  position: absolute;
  top: 0;
  left: -200px;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: calc(100% + 200px);
  height: 100%;
}
