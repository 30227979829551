/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

// This constrains the horizontal rules to the same width as the
// described form groups.
.externalAuthProviderForm {
  max-width: 800px;
}

// Sometimes the form controls in an EuiDescribedFormLayout result
// in there being excess whitespace at the top. This custom `--none`
// class corrects this.
.euiDescribedFormGroup__fieldPadding--none .euiDescribedFormGroup__fields {
  padding-top: 0;
}

.titleWithBadge {
  flex-grow: 0;
  padding-bottom: 10px;

  h3 {
    margin-bottom: 0;
  }
}

.euiRadio-asLabel {
  label {
    font-size: $euiFontSizeXS !important;
    font-weight: $euiFontWeightMedium;
  }
}

// Make the accordion button more noticeable
.authProvider-advancedSettingsAccordion {
  .euiAccordion__button {
    color: $euiLinkColor;
  }
}

// A bit of breathing room before the order icons
.externalAuthList-order {
  display: inline-block;
  padding: 0 0 0 $paddingSmall;
}

// Hacky I know, but each is required to get a button to full width
// when using a Popover component as it nests inline-block elements :(
@media screen and (max-width: $euiBreakpointM) {
  .euiPageContentHeader {
    > .euiPageContentHeaderSection:last-child {
      width: 100%;

      > .euiPopover {
        width: 100%;

        > .euiPopover__anchor {
          width: 100%;

          > .euiButton {
            width: 100%;
          }
        }
      }
    }
  }
}
