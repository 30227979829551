/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

.platformVisualization-vizContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}

.platformVisualization-vizItemContainer {
  min-width: 21rem;
  padding: 1rem;
  margin: 1rem;
}

.platformVisualization-vizItemInnerContainer {
  align-self: center;
  position: relative;
}

.platformVisualization-vizItemContainer-title {
  font-size: $euiFontSizeL;
  white-space: nowrap;
}

.platformVisualization-gradient--maintenance {
  color: rgba(235, 255, 0, 0.5);
}

.platformVisualization-gradient--unhealthy {
  color: rgba(0, 0, 0, 0.3);
}

.platformVisualizationLegend-items {
  display: flex;
}

.platformVisualizationLegend-item {
  display: flex;
  font-size: $euiFontSizeXS;
  margin-right: $paddingSmall;
  align-items: center;
}

.platformVisualizationLegend-itemBox {
  width: .8rem;
  height: .8rem;
  margin-right: $paddingTiny;
  border-radius: $borderRadius;
}

.platformVisualizationHexagon-exclamation {
  font-size: 1.1rem;
  pointer-events: none;
}

.platformVisualizationHexagon-exclamation--small {
  font-size: .7rem;
}

.platformVisualization-tooltip {
  position: absolute;
  padding: $paddingSmaller $paddingBase;
  background-color: $euiColorDarkestShade;
  color: $euiColorEmptyShade;
  font-size: $euiFontSizeXS;
  border-radius: $borderRadius;
  pointer-events: none;
  max-width: 14rem;

  &::after {
    content: '';
    position: absolute;
    right: 100%;
    top: 44%;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid $euiColorDarkestShade;
  }
}

.platformVisualizationTooltip-title {
  font-weight: bold;
  margin-bottom: $paddingTiny;
}

.platformVisualizationTooltip-unhealthy {
  text-align: center;
}

.platformVisualizationTooltip-unhealthyIcon {
  margin-top: $paddingSmall;
  font-size: 1rem;
}
