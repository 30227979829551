/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

.create-deployment-panel {
  width: auto;
  margin: 0 auto;
  max-width: 820px;

  .create-deployment-placeholder .euiFieldText {
    color: $euiColorMediumShade;

    &:focus {
      color: $euiColorDarkestShade;
    }
  }
}

.create-deployment-panel-title {
  padding: 60px 60px $euiSizeS 60px;

  @media screen and (max-width: $euiBreakpointM) {
    padding: $paddingLarger;
  }

  @media screen and (max-width: $euiBreakpointS) {
    padding-bottom: 0;
  }
}

.create-deployment-panel-body {
  padding: $euiSizeS 60px;

  @media screen and (max-width: $euiBreakpointM) {
    padding: $euiSizeS;
  }
}

.euiPanel .create-deployment-footer {
  border-radius: 0 0 4px 4px!important;
  padding: $euiSizeL 60px;
  box-shadow: 0 -8px 8px -8px $euiColorLightShade;

  .euiTheme-dark & {
    box-shadow: 0 -8px 8px -8px tint($euiColorInk, 5%);
  }

  @media screen and (max-width: $euiBreakpointM) {
    padding: $paddingSmall $paddingLarger;

    .create-deployment-footer-price {
      margin-bottom: $paddingLarge !important;
    }
  }
}

.deployment-architecture-summary-visible,
.deployment-architecture-summary-hidden {
  overflow: hidden;
}

.deployment-architecture-summary-hidden {
  max-height: 0;
  transition: max-height .55s ease-out;
}

.deployment-architecture-summary-visible {
  max-height: 500px;
  transition: max-height .75s ease-in;
}

.configure-deployment-steps-accordion {
  .euiAccordion__triggerWrapper {
    button {
      color: $euiLinkColor;
    }
  }
}
