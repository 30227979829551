/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

.nodes-visualization-instance {
  &.nodes-visualization-instance-warning {
    border-color: $euiColorWarning;
  }

  &.nodes-visualization-instance-danger {
    border-color: $euiColorDanger;
  }

  &.nodes-visualization-instance-paused {
    .nodes-visualization-instance-title,
    .node-visualization-attribute-list,
    .node-instance-stats-display {
      opacity: 0.7;
    }
  }

  .nodes-visualization-instance-name {
    text-transform: uppercase;
  }

  .euiHorizontalRule {
    margin: 0 0 $paddingLargest;
  }

  .node-visualization-allocator-link {
    margin-left: $paddingSmaller;
  }
}
