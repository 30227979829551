/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

.drift-button.euiButtonIcon--empty {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 54px;
  height: 54px;
  display: block;
  background-color: $euiColorLightestShade;
  box-shadow: 0 0.9px 4px -1px rgba(0,0,0,0.08),0 2.6px 8px -1px rgba(0,0,0,0.06),0 5.7px 12px -1px rgba(0,0,0,0.05),0 15px 15px -1px rgba(0,0,0,0.04) !important;

  & > img {
    width: 100%;
  }

  .euiTheme-dark & {
    background-color: $euiColorEmptyShade;
  }
}
