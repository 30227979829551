/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

.userFlyoutForm {
  .euiFlyoutBody {
    display: flex;
    flex-direction: column;
  }

  .euiForm {
    flex-grow: 1;
  }

  .euiAccordion__button {
    .euiTheme-light & {
      color: $euiLinkColor;

      &:hover {
        color: darken($euiLinkColor, 10%);
      }
    }
  }

  .euiAccordion__padding--m {
    padding-bottom: 0;
  }
}

@include euiBreakpoint('xs', 's') {
  .userFlyoutForm--closeButton {
    // You'd think tha we should set this to 100%, but due to the cross icon,
    // that makes the text look oddly offset. Knocking back the width makes
    // it look more center-aligned.
    width: 90%;
  }
}
