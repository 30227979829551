/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

.applicationLinks ul {
  display: table;

  & > * {
    display: table-row;

    & > * {
      display: table-cell;
      padding-bottom: $paddingTight;
      padding-right: $paddingLargest;
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: $euiBreakpointM) {
  .applicationLinks ul {
    & > * > * {
      // stack the links/copiers
      display: block;

      // but keep the product name in the left margin alongside them
      &:first-child {
        display: table-cell;
      }

      // and space them out a bit more from each other
      &:last-child {
        padding-bottom: $paddingLarge;
      }
    }
  }
}

.applicationLink-unavailable {
  color: $euiColorLightShade;
  cursor: default;
  letter-spacing: normal;

  .euiTheme-dark & {
    color: $euiColorDarkShade;
  }
}

.applicationLink-help {
  width: 15rem;
}
