/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '@modules/cui/styles/variables.scss';

.login-form-sso {
  text-align: center;
}

@include euiBreakpoint('m', 'l', 'xl') {
  .login-form-sso {
    margin-top: 42vh;
  }

  .login-form-password {
    width: 18rem;
  }

  .reset-password-notice,
  .set-password-form {
    width: 22rem;
  }

  .reset-password-notice {
    margin-top: 15vh;
  }

  .login-form-password {
    margin-top: 20vh;
  }
}

@include euiBreakpoint('xs', 's') {
  .reset-password-notice {
    padding: 0 $paddingEvenLarger;
  }
}

.reset-password-notice {
  text-align: center;
}

.forgotPasswordLink {
  flex-grow: 3;
  text-align: right;
  margin-right: $paddingTight;

  @media screen and (max-width: $euiBreakpointM) {
    font-size: $euiFontSizeS;
    text-align: center;
    margin-top: $euiSizeXS;
    margin-bottom: $euiSizeS;
  }
}

.login-form-email {
  margin-bottom: $paddingLarge;
}

.login-feed-container {
  justify-content: center;
  margin-right: 80px;
  min-width: 22.5rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 22.5rem;
  }

  .euiTitle {
    margin-bottom: $euiSizeL;
  }
  @media screen and (max-width: $euiBreakpointL) {
    display: none;
  }

  .landing-image {
    .euiTitle {
      width: 23rem;
      margin-left: 0;
    }

    &.landingImage-elasticon .euiTitle {
      width: 25rem;
    }
  }
}

.login-page-container {
  align-content: center;

  .trial-message-login {
    width: 100%;
    margin: auto $paddingEvenLarger;

    .trial-message-login-callout {
      max-width: 51rem;
    }
  }

  &.euiFlexGroup--gutterExtraLarge>.euiFlexItem {
    margin: 1.5rem 2.75rem;
  }

  .trial-message-login {
    flex-basis: 100%;

    .trial-message-login-callout {
      margin: $paddingLarge auto 0;
      max-width: 51rem;
    }
  }

  .login-form-container {
    width: 22.5rem;

    @media screen and (max-width: $euiBreakpointS) {
      width: auto;
    }

    .euiTitle {
      margin-bottom: $euiSizeXL;

      @media screen and (max-width: $euiBreakpointM) {
        font-size: 1.3rem;
        line-height: normal;
        margin-bottom: $euiSizeL;
        font-weight: 400;
      }

      &.mfa-page-title {
        margin-bottom: $euiSize;
      }
    }
  }

  .login-form-password-field {
    margin-top: 1.4rem;
  }

  .login-form-password {
    margin-top: 0;
    width: auto;
  }
}
